import React from "react"
import { Loader as PaxLoader } from "@partner-tech/pax-ui-kit"

interface CenteredLoaderProps {
    label?: string
    className?: string
}

export const LoadingSpinner = ({ label, className }: CenteredLoaderProps) => {
    return (
        <div className="flex h-full justify-center">
            <PaxLoader loading={true} label={label} variant="indeterminate" className={className}>
                <span />
            </PaxLoader>
        </div>
    )
}
