import React, { useState } from "react"
import { useNavigate } from "react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { AvatarAIcon } from "@partner-tech/pax-icons"
import { ListItemIcon, ListItemText } from "@partner-tech/pax-ui-kit"
import { Button, Menu, MenuItem, Select } from "@tb/desy"
import cn from "classnames"
import { Circle } from "@/components/Icons/Circle"
import { Key } from "@/components/Icons/Key"
import { Logout } from "@/components/Icons/Logout"
import { config } from "@/config"
import { usePlatform } from "@/contexts/PlatformContext"
import { useUserContext } from "@/contexts/UserContext"
import { PageRoutes } from "@/utils/constants"

export const ProfileMenu = () => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const navigate = useNavigate()
    const { user, hasPermission, merchants, merchant, setMerchant } = useUserContext()
    const { logout } = useAuth0()
    const { isWlz } = usePlatform()

    const handleClick = (event: React.MouseEvent<Element>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = () => {
        logout({ logoutParams: { returnTo: config.auth.AUTH0_LOGOUT_URL } })
            .then(() => console.log("Logged out successfully!"))
            .catch((error) => {
                console.error("Failed to logout", error)
            })
        handleClose()
    }

    const handleCredentials = () => {
        navigate(PageRoutes.CREDENTIALS)
        handleClose()
    }

    const handleSelectMerchant = (merchantCode: string) => {
        setMerchant(merchantCode)
        handleClose()
    }

    const handleSuperAdmin = () => {
        setMerchant(undefined)
        navigate(PageRoutes.MERCHANTS)
        handleClose()
    }

    const adminModeActive = !merchant
    return (
        <div className="mt-auto w-full">
            <div
                className={cn(`flex w-full flex-col items-center justify-between text-background-default`, {
                    "text-black": isWlz,
                })}
            >
                <Button
                    variant={isWlz ? "outlined" : "text"}
                    onClick={handleClick}
                    id="profile-menu-button"
                    aria-label="Profile menu"
                    // redundant if component is fixed in TB.Desy
                    sx={{
                        "&.MuiButton-root.MuiButton-outlined": {
                            color: "black",
                            borderColor: "black",
                        },
                    }}
                >
                    <AvatarAIcon />
                </Button>
                <div className={"mt-2 line-clamp-2 w-full break-words text-center text-sm"}>{user?.name}</div>
            </div>
            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: 75,
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                {hasPermission("switch_merchants") && (
                    <MenuItem className={"py-4"}>
                        <ListItemIcon>
                            <Circle className={`${adminModeActive ? "fill-stormy-300" : "fill-laurel-500"} h-4`} />
                        </ListItemIcon>
                        <Select
                            className={"!w-64"}
                            fullWidth={true}
                            label={"Select Merchant"}
                            search
                            multiple={false}
                            value={merchant?.merchant_code ?? ""}
                            options={merchants.map((merchant) => ({
                                value: merchant.merchant_code,
                                displayValue: merchant.merchant_name,
                                label: merchant.merchant_code,
                            }))}
                            renderItem={(item) => (
                                <MenuItem key={item.option.value} value={item.option.value} className={"my-1 flex flex-col items-start"}>
                                    <div className={"max-w-56 truncate"}>{item.option.displayValue}</div>
                                    <div className={"text-xs text-gray-500"}>{item.option.label}</div>
                                </MenuItem>
                            )}
                            onChange={handleSelectMerchant}
                        />
                    </MenuItem>
                )}
                {hasPermission("switch_merchants") && (
                    <MenuItem onClick={handleSuperAdmin}>
                        <ListItemIcon>
                            <Circle className={`${adminModeActive ? "fill-laurel-500" : "fill-stormy-300"} h-4`} />
                        </ListItemIcon>
                        <ListItemText primary="Admin Mode" secondary={adminModeActive ? "Active" : ""} />
                    </MenuItem>
                )}
                {hasPermission("read:client_credentials") && !adminModeActive && (
                    <MenuItem onClick={handleCredentials}>
                        <ListItemIcon>
                            <Key className={"h-4 fill-stormy-700"} />
                        </ListItemIcon>
                        <ListItemText primary={"API Client Credentials"} />
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout className={"h-4 fill-stormy-700"} />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </MenuItem>
            </Menu>
        </div>
    )
}
