import { createContext, ReactNode, useContext, useMemo } from "react"
import { config } from "@/config"

export enum Platform {
    MFI = "mfi",
    WLZ = "wlz",
}

export enum Environment {
    DEVELOPMENT = "development",
    PREVIEW = "preview",
    TEST = "test",
    CAT = "cat",
    PRODUCTION = "production",
}

export const getPlatform = (): Platform => {
    return import.meta.env.VITE_PLATFORM === Platform.WLZ ? Platform.WLZ : Platform.MFI
}

export const getEnvironment = (): Environment => {
    const envName = config.settings.ENV_NAME?.toLowerCase()?.split("-")[0] ?? "production"
    if (!Object.values(Environment).includes(envName as Environment)) {
        console.warn(`Unknown environment: ${envName}, defaulting to production`)
        return Environment.PRODUCTION
    }
    return envName as Environment
}

interface PlatformConfig {
    platform: Platform
    environment: Environment
    isMfi: boolean
    isWlz: boolean
    isDevEnv: boolean
    isTestEnv: boolean
    isCatEnv: boolean
    isProdEnv: boolean
    isDevOrTestEnv: boolean
    logo: {
        primary: string
        secondary: string
    }
}

const logos: Record<Platform, PlatformConfig["logo"]> = {
    [Platform.MFI]: {
        primary: "/mfi/tb-mfi-white-logo.png",
        secondary: "/mfi/tb-mfi-black-logo.png",
    },
    [Platform.WLZ]: {
        primary: "/wlz/android-chrome-192x192.png",
        secondary: "/wlz/android-chrome-192x192.png",
    },
}

const createPlatformConfig = (platform: Platform, environment: Environment): PlatformConfig => {
    const isDevEnv = [Environment.DEVELOPMENT, Environment.PREVIEW].includes(environment)
    const isTestEnv = environment === Environment.TEST
    const isCatEnv = environment === Environment.CAT
    const isProdEnv = environment === Environment.PRODUCTION
    const isDevOrTestEnv = isDevEnv || isTestEnv
    return {
        platform,
        environment,
        isDevEnv,
        isTestEnv,
        isCatEnv,
        isProdEnv,
        isDevOrTestEnv,
        isMfi: platform === Platform.MFI,
        isWlz: platform === Platform.WLZ,
        logo: logos[platform],
    }
}

const PlatformContext = createContext<PlatformConfig>(createPlatformConfig(Platform.MFI, Environment.PRODUCTION))

export const usePlatform = () => useContext(PlatformContext)

interface PlatformProviderProps {
    children: ReactNode
}

export const PlatformProvider = ({ children }: PlatformProviderProps) => {
    const platform = getPlatform()
    const environment = getEnvironment()
    const value = useMemo(() => createPlatformConfig(platform, environment), [platform, environment])

    return <PlatformContext.Provider value={value}>{children}</PlatformContext.Provider>
}
