import { Circle } from "@/components/Icons/Circle"
import { useUserContext } from "@/contexts/UserContext"

export const MerchantContextIndicator = () => {
    const { merchant, isLoading, hasPermission } = useUserContext()

    if (isLoading || !merchant || !hasPermission("switch_merchants")) {
        return null
    }
    return (
        <div className={`sticky flex h-6 items-center justify-center gap-2 bg-laurel-75`}>
            <Circle className={"h-2 fill-laurel-300"} />
            <span className={"text-xs font-bold"}>You are viewing {merchant?.merchant_name}</span>
        </div>
    )
}
