import React, { Suspense } from "react"
import { Outlet } from "react-router"
import { LoadingScreen } from "@/components/LoadingScreen"
import { Sidebar } from "@/components/Sidebar"
import { UserProvider } from "@/contexts/UserContext"
import { MerchantContextIndicator } from "@/layouts/MerchantContextIndicator"

export const Layout = () => {
    return (
        <UserProvider>
            <div className="flex h-screen w-full overflow-hidden">
                <Sidebar />
                <main className="flex-1 overflow-y-auto overflow-x-hidden bg-canvas">
                    <MerchantContextIndicator />
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </main>
            </div>
        </UserProvider>
    )
}
