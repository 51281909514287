type CircleProps = {
    className?: string
}
export const Circle = ({ className }: CircleProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={className}>
            <circle cx="50" cy="50" r="50" />
        </svg>
    )
}
