import { DesyThemeProvider } from "@tb/desy"
import { PlatformProvider } from "@/contexts/PlatformContext"
import { Router } from "@/router"

export const App = () => {
    return (
        <PlatformProvider>
            <DesyThemeProvider>
                <Router />
            </DesyThemeProvider>
        </PlatformProvider>
    )
}
